import '../ui-anchor/ui-anchor.lt.js';
import '../ui-annotation/ui-annotation.lt.js';
import '../ui-assets/ui-assets.lt.js';
import '../ui-autocomplete/ui-autocomplete.lt.js';
import '../ui-breadcrumbs/ui-breadcrumbs.lt.js';
import '../ui-calendar/ui-calendar.lt.js';
import '../ui-chip/ui-chip.lt.js';
import '../ui-comparison-cards/ui-comparison-cards.lt.js';
import '../ui-cookie-consent/ui-cookie-consent.lt.js';
import '../ui-countdown/ui-countdown.lt.js';
import '../ui-curtain/ui-curtain.lt.js';
import '../ui-datepicker/ui-datepicker.lt.js';
import '../ui-daterange/ui-daterange.lt.js';
import '../ui-dialogbubble/ui-dialogbubble.lt.js';
import '../ui-dropdown/ui-dropdown.lt.js';
import '../ui-feedback/ui-feedback.lt.js';
import '../ui-footer/ui-footer.lt.js';
import '../ui-form/ui-form.lt.js';
import '../ui-message/ui-message.lt.js';
import '../ui-modal/ui-modal.lt.js';
import '../ui-monthpicker/ui-monthpicker.lt.js';
import '../ui-nav/ui-nav.lt.js';
import '../ui-navitem/ui-navitem.lt.js';
import '../ui-navlist/ui-navlist.lt.js';
import '../ui-navtoggle/ui-navtoggle.lt.js';
import '../ui-offer/ui-offer.lt.js';
import '../ui-pagination/ui-pagination.lt.js';
import '../ui-phonefield/ui-phonefield.lt.js';
import '../ui-searchinput/ui-searchinput.lt.js';
import '../ui-signing/ui-signing.lt.js';
import '../ui-slider/ui-slider.lt.js';
import '../ui-slides/ui-slides.lt.js';
import '../ui-stepper/ui-stepper.lt.js';
import '../ui-table-cloneable/ui-table-cloneable.lt.js';
import '../ui-table-expandable/ui-table-expandable.lt.js';
import '../ui-table-sortable/ui-table-sortable.lt.js';
import '../ui-tabs/ui-tabs.lt.js';
import '../ui-textcounter/ui-textcounter.lt.js';
import '../ui-timerange/ui-timerange.lt.js';
import '../ui-tooltip/ui-tooltip.lt.js';
import '../ui-upload-item/ui-upload-item.lt.js';
import '../ui-upload/ui-upload.lt.js';
import '../ui-video/ui-video.lt.js';
import '../ui-wizard/ui-wizard.lt.js';
import { Labels } from '../../shared-components/global/labels.js';

Labels.LANGUAGE = 'lt';

window.UI = { ...window.UI, Labels };
